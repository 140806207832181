import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "type-safety-at-runtime-with-json-decoders"
    }}>{`Type safety at runtime with JSON decoders`}</h1>
    <h5 {...{
      "id": "september-25-2019--wrocław-typescript"
    }}>{`September 25, 2019 | `}<a parentName="h5" {...{
        "href": "https://github.com/WrocTypeScript/"
      }}>{`Wrocław TypeScript`}</a></h5>
    <hr></hr>
    <ul>
      <li parentName="ul">{`📊 `}<strong parentName="li">{`Slides`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://slides.com/kajetansw/type-safety-at-runtime"
        }}>{`https://slides.com/kajetansw/type-safety-at-runtime`}</a></li>
      <li parentName="ul">{`⌚ `}<strong parentName="li">{`Duration`}</strong>{`: ~5 minutes`}</li>
      <li parentName="ul">{`👨‍💻 `}<strong parentName="li">{`Form`}</strong>{`: lighting talk`}</li>
    </ul>
    <hr></hr>
    <p>{`As we all know, TypeScript’s adds static typing and compile-time type checking to our beloved JavaScript. But wait… What about runtime? How do I know, that my application still gets valid data from the external REST API?`}</p>
    <p>{`Don’t worry, JSON decoders come to the rescue!`}</p>
    <p>{`During this talk, all participants got a brief introduction to the concept of JSON decoders and how to use them in TypeScript.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      